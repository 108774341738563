require("dotenv").config();
const BASE_URL = process.env.VUE_APP_LOGIN_BASE;
const axios = require("axios").create({
    baseURL: BASE_URL,
    timeout: 60000,
});
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) => {
        const originalUrl = err.config;
        const {
            response: {status},
        } = err;
        if (status === 401) {
            //Sets the cookies
            console.log("Refreshing token")
            await refreshToken()
            return axios(originalUrl)
        } else {
            return Promise.reject(err)
        }
    }
);

//Authentication

const signIn = (email, password) => {
    return axios.post("/login", {
        email: email,
        password: password,
        returnSecureToken: true,
    });
};

const signOut = () => {
    return axios.get("/logout")
}

const forgotPassword = (email) => {
    return axios.post("/login/forgot", {
        email: email
    })
}

const refreshToken = () => {
    return axios.get("/refresh");
};

//Users

const getUsers = (size, query) => {
    // axios.defaults.withCredentials = true;
    if (size !== null) {
        return axios.get("/user/list", {
            params: {
                size: parseInt(size),
                query: query,
            },
        });
    } else {
        return axios.get("/user/list");
    }
};

const deleteUser = (uid) => {
    return axios.delete("/user", {
        data: {
            userId: uid
        }
    })
}

const deleteMultipleUsers = (uids) => {
    console.log(`deleteMultipleUsers: ${uids}`)
    return axios.delete("/user/multiple", {
        data: {
            userId: uids
        }
    })
}

//Admins

const getAdmins = () => {
    return axios.get("/admin/list")
}

const addAdmin = (admin) => {
    const formData = new FormData()
    formData.append("email", admin.email)
    formData.append("password", admin.password)
    formData.append("password2", admin.password2)
    formData.append("phone", admin.phone)
    formData.append("name", `${admin.firstName} ${admin.lastName}`)
    if (admin.files) {
        for (let i = 0; i < admin.files.length; i++) {
            let file = admin.files[i]
            formData.append(`files`, file)
        }
    }
    return axios.post("/admin", formData)
}

const deleteAdmin = (userId) => {
    return axios.delete("/admin", {
        data: {
            userId: userId
        }
    })
}

const updateAdmin = (admin) => {
    const formData = new FormData()
    formData.append("name", admin.name)
    formData.append("profilePicture", admin.profilePicture)
    if (admin.file) {
        formData.append("files", admin.file)
    }
    formData.append("userId", admin.userId)
    formData.append("email", admin.email)
    return axios.put("/admin", formData)
}

const getAdminDetails = (email) => {
    return axios.get(`/admin/${email}`)
}

//Landmarks
const generateLandmarkFormData = (landmark) => {
    const formData = new FormData()
    formData.append("name", landmark.name)
    formData.append("lat", landmark.lat)
    formData.append("lng", landmark.lng)
    formData.append("address", landmark.address)
    if (landmark.images) {
        for (let i = 0; i < landmark.images.length; i++) {
            let image = landmark.images[i]
            formData.append("images", image)
        }
    }
    if (landmark.files) {
        for (let i = 0; i < landmark.files.length; i++) {
            let file = landmark.files[i]
            formData.append(`files`, file)
        }
    }
    return formData
}

const addLandmark = (landmark) => {
    const formData = generateLandmarkFormData(landmark)
    formData.append("desc", landmark.desc)
    return axios.post("/landmark", formData)
}

const updateLandmark = (landmark) => {
    const formData = generateLandmarkFormData(landmark)
    formData.append("geoPoint[_latitude]", landmark.geoPoint._latitude)
    formData.append("geoPoint[_longitude]", landmark.geoPoint._longitude)
    formData.append("addressLower", landmark.addressLower)
    formData.append("coordinatesDms", landmark.coordinatesDms)
    formData.append("nameLower", landmark.nameLower)
    formData.append("id", landmark.id)
    formData.append("description", landmark.description)
    for (let i = 0; i < landmark.keywords.length; i++) {
        formData.append("keywords", landmark.keywords[i])
    }
    return axios.put("/landmark", formData)
}

const getLandmarkList = (size, query) => {
    if (size !== null) {
        return axios.get("/landmark/list", {
            params: {
                size: parseInt(size),
                query: query
            }
        })
    } else {
        return axios.get("/landmark/list")
    }
}

const deleteLandmark = (id) => {
    return axios.delete("/landmark", {
        data: {
            id: id
        }
    })
}

const searchLandmark = (query) => {
    return axios.get("/landmark/", {
        params: {
            search: query
        }
    })
}

export default {
    signIn,
    getUsers,
    refreshToken,
    forgotPassword,
    signOut,
    getAdmins,
    addAdmin,
    getLandmarkList,
    deleteUser,
    deleteMultipleUsers,
    deleteAdmin,
    addLandmark,
    searchLandmark,
    deleteLandmark,
    updateLandmark,
    updateAdmin,
    getAdminDetails
};
