<template>
    <div class="the-home">
        <teleport to="#modal ">
            <ProgressBar v-if="isLoading" mode="indeterminate"/>
        </teleport>
        <the-sidebar
                v-if="isDrawerOpen"
                @on-close="isDrawerOpen = false"
        ></the-sidebar>
        <app-bar @drawer-open="isDrawerOpen = true" @on-error="handleError"
                 @loading="handleLoad"></app-bar>
        <router-view @loading="handleLoad"></router-view>
    </div>
</template>

<script>
    import AppBar from "../ui/appbar/AppBar.vue";
    import TheSidebar from "../ui/sidebar/TheSidebar.vue";

    export default {
        data() {
            return {
                isDrawerOpen: false,
                isLoading: false,
                severity: "success"
            };
        },
        components: {
            AppBar,
            TheSidebar,
        },
        watch: {
            isDrawerOpen() {
                document.querySelector("body").style.backgroundColor = this.isDrawerOpen
                    ? "rgba(0,0,0,0.4)"
                    : null;
            },
        },
        methods: {
            handleLoad(loadBool) {
                console.log("handleLoad")
                this.isLoading = loadBool
            },
            handleError(error) {
                this.showToast(error, "error")
            },
            showToast(message, severity) {
                this.$toast.add({severity: severity, detail: message, life: 3000});
            },
        }
    };
</script>
