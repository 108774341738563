<template>
    <teleport to="#modal">
        <!--    <va-progress-bar indeterminate v-if="isLoading"/>-->
        <ProgressBar v-if="isLoading" mode="indeterminate"/>
    </teleport>
    <div class="grid h-screen w-full" :class="[isOnLogin ? split : white]">
        <div class="col-1 lg:col-4"></div>
        <div class="col-10 lg:col-4">
            <!--            <login-form @is-loading="handleLoad"></login-form>-->
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" @is-loading="handleLoad" @on-error="handleError"
                               @on-success="handleSuccess"/>
                </keep-alive>
            </router-view>
        </div>
        <div class="col-1 lg:col-4"></div>
    </div>
    <Toast position="bottom-right"/>
</template>

<script>
    // import LoginForm from "./LoginForm.vue";

    import ProgressBar from 'primevue/progressbar';

    export default {
        data() {
            return {
                isLoading: false,
                isOnLogin: true,
                split: 'login-container',
                white: 'login-container-2'
            };
        },
        components: {
            ProgressBar,
        },
        methods: {
            handleLoad(isLoading) {
                this.isLoading = isLoading;
            },
            handleError(error) {
                this.showToast(error, "error")
            },
            handleSuccess() {
                this.showToast("Logged in successfully!", "success")
            },
            showToast(message, severity) {
                this.$toast.add({
                    severity: severity,
                    detail: message,
                    group: 'br',
                    life: 3000
                });
            },
        },
        watch: {
            $route(to) {
                if (to.fullPath === "/user/forgot") {
                    this.isOnLogin = false
                } else if (to.fullPath === "/user/login") {
                    this.isOnLogin = true
                }
            }
        }
    };
</script>

<style scoped>
    .login-container {
        background-color: #013A6B;
        background-image: -webkit-linear-gradient(-63deg, #011328 50%, white 50%);
    }

    .login-container-2 {
        background-image: url("../../assets/forgot_bg.png");
        background-size: cover;
    }
</style>
