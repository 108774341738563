<template>
    <div class="app-bar h-4rem lg:h-5rem">
        <i
                id="hamburger"
                class="pi pi-bars pl-4"
                style="color: white"
                @click="initDrawerOpen"
        ></i>
        <p class="title text-base lg:text-xl"><b>Welcome, admin!</b></p>
        <div class="mr-7">
            <img :src="profilePicture" alt="Avatar"
                 class="avatar"
                 @click="showOverlay">
        </div>
        <OverlayPanel ref="op" appendTo="body">
        </OverlayPanel>
        <Menu ref="menu" :model="menuItems" :popup="true"/>
        <ConfirmDialog group="appBar"></ConfirmDialog>
    </div>
</template>

<script>
    import OverlayPanel from 'primevue/overlaypanel';
    import Menu from 'primevue/menu';
    import ConfirmDialog from 'primevue/confirmdialog';

    import apiService from "../../../service/apiService";
    import localStorageUtil from "../../../utils/localStorageUtil";

    export default {
        emits: ["drawer-open", "on-error", "on-signout", "loading"],
        components: {
            OverlayPanel,
            Menu,
            ConfirmDialog
        },
        data() {
            return {
                isLogoutOpen: false,
                menuItems: [
                    {
                        label: "Edit Profile",
                        icon: "pi pi-user-edit",
                        command: () => {
                            this.$router.push({
                                name: "edit"
                            })
                        }
                    },
                    {
                        label: "Logout",
                        icon: "pi pi-sign-out",
                        command: () => {
                            this.showDialog()
                        }
                    },
                ],
                profilePicture: null
            }
        },
        methods: {
            initDrawerOpen() {
                this.$emit("drawer-open");
            },
            showOverlay(event) {
                this.toggle(event)
                this.toggleMenu(event)
            },
            showDialog() {
                this.$confirm.require({
                    group: "appBar",
                    message: "Are you sure you want to sign out?",
                    header: "Confirmation",
                    accept: async () => {
                        this.$emit("loading", true)
                        await this.signOut()
                    },
                    reject: () => {

                    }
                })
            },
            toggle(event) {
                this.$refs.op.toggle(event);
            },
            toggleMenu(event) {
                this.$refs.menu.toggle(event);
            },
            async signOut() {
                await apiService.signOut().catch(error => {
                    this.$emit("on-error", error.response.data.message)
                    this.$emit("loading", false)
                })
                this.$emit("loading", false)
                localStorageUtil.remove("isLoggedIn")
                localStorage.removeItem("id")
                localStorage.removeItem("email")
                localStorage.removeItem("name")
                localStorage.removeItem("profilePicture")
                localStorage.removeItem("phone")
                await this.$router.replace("login")
            },
            getDp() {
                if (localStorage.getItem("profilePicture")) {
                    this.profilePicture = localStorageUtil.getProfilePicture()
                } else {
                    this.profilePicture = require("../../../assets/no_dp.png")
                }
            }
        },
        created() {
            this.getDp()
        }
    };
</script>

<style scoped>
    .avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .app-bar {
        justify-content: space-between;
        align-items: center;
        display: flex;
        flex-direction: row;
        overflow: auto;
        background: #071D36;
        margin-top: 0;
        top: 0;
    }

    .title {
        color: white;
    }

    #hamburger, .avatar {
        cursor: pointer;
    }
</style>

