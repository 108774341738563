<template>
    <!--    <ProgressBar class="mt-2" v-if="isLoading" mode="indeterminate"/>-->
    <div class="accounts-list">
        <DataTable
                class="p-datatable-admins mt-4"
                :loading="isLoading"
                :paginator="true"
                :rows="10"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :value="users"
                :dataKey="id"
                responsiveLayout="scroll"
                v-model:selection="selectedUsers"
                :row-hover="true"
                v-model:filters="filters"
                @page="fetchAnother"
        >
            <template #header>
                <div class="container search-bar">
                    <va-input class="mr-1"
                              v-model="filters['global'].value"
                    >
                        <template #prepend>
                            <va-icon name="search"/>
                        </template
                        >
                    </va-input>
                    <Button label="Search" class="ml-1"/>
                </div>
            </template>
            <template #empty>
                No users found.
            </template>
            <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
            <Column field="name" header="Name"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="action" header="Action">
                <template #body="slotProps">
                    <Button
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-text p-button-plain"
                            style="color: red"
                            @click="handleDeletion(slotProps.data)"
                    />
                </template>
            </Column>
        </DataTable>
    </div>
    <ConfirmDialog  :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" group="accountList"></ConfirmDialog>
    <Toast position="bottom-right"/>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import ConfirmDialog from "primevue/confirmdialog"

    import {FilterMatchMode} from "primevue/api";

    import apiService from "../../../../service/apiService";

    export default {
        components: {
            DataTable,
            Column,
            Button,
            ConfirmDialog,
        },
        methods: {
            showToast(message, severity) {
                this.$toast.add({
                    severity: severity,
                    detail: message,
                    group: 'br',
                    life: 3000
                });
            },
            showDialog(message, accept, reject) {
                this.$confirm.require({
                    group: "accountList",
                    message: message,
                    header: "Confirmation",
                    accept: accept,
                    reject: reject
                })
            },
            handleDeletion(data) {
                let message = ""
                if (Array.isArray(this.selectedUsers) && this.selectedUsers.length) {
                    const ids = []
                    this.selectedUsers.map(user => {
                        ids.push(user.id)
                    })
                    message = `Are you sure you want to remove ${ids.join()}?`
                    this.showDialog(message, () => {
                        return this.deleteUsers(ids)
                    }, () => {

                    })
                } else {
                    message = `Are you sure you want to remove ${data.email}?`
                    this.showDialog(message, () => {
                        return this.deleteUser(data)
                    }, () => {

                    })
                }
            },
            async deleteUser(data) {
                this.isLoading = true
                await apiService.deleteUser(data.id).catch(error => {
                    this.isLoading = false
                    this.showToast(error, "error")
                })
                this.$confirm.close();
                this.isLoading = false
                this.showToast("User has been deleted successfully!", "success")
                this.users = this.users.filter(user => user !== data)
            },
            async deleteUsers(ids) {
                try {
                    this.isLoading = true
                    await apiService.deleteMultipleUsers(ids)
                    this.$confirm.close();
                    this.isLoading = false
                    this.showToast("Users has been deleted successfully!", "success")
                    this.selectedUsers.map(user => {
                        this.users = this.users.filter(u => u !== user)
                    })
                } catch (error) {
                    this.isLoading = false
                    this.showToast(error, "error")
                }
            },
            async fetchAnother() {
                if (this.currentQuery !== null) {
                    const response = await apiService
                        .getUsers(10, this.currentQuery)
                        .catch((error) => {
                            this.showToast(error.response.data.message);
                        });
                    this.currentQuery = response.data.data.query;
                    this.users.push(...response.data.data.users);
                }
            },
        },
        data() {
            return {
                severity: "success",
                isLoading: true,
                selectedUsers: null,
                users: [],
                currentQuery: "",
                filters: {
                    "global": {value: null, matchMode: FilterMatchMode.CONTAINS},
                },
            };
        },
        async created() {
            this.isLoading = true;
            try {
                const response = await apiService.getUsers(10, null);
                this.currentQuery = response.data.data.query;
                this.isLoading = false;
                this.users = response.data.data.users;
                await this.fetchAnother();
            } catch (error) {
                this.showToast(error.response.data.message);
                this.isLoading = false;
            }
        },
        emits: ["loading"]
    };
</script>

<style scoped>
    ::v-deep(.p-datatable-header) {
        background-color: white;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    button {
        background-color: #338aa1;
        border-color: #338aa1;
        /*  uncomment if asked to fix
        width: 173px;
        height: 54px;

        background: #338AA1;
        border-radius: 10px;

        font-style: normal;
        font-weight: normal;
        font-size: 25px !important;
        line-height: 34px; */
    }
</style>
