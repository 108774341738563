<template>
    <div class="landmarks-list container">
        <div class="row">
            <DataTable
                    :loading="isLoading"
                    :paginator="true"
                    :rows="10"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    class="mt-4"
                    :value="landmarks"
                    :dataKey="id"
                    responsiveLayout="scroll"
                    v-model:selection="selectedLandmarks"
                    :row-hover="true"
                    v-model:filters="filters"
                    @page="fetchAnother"
            >
                <template #header>
                    <div class="flex flex-column">
                        <div class="header-container">
                            <va-input class="mr-1"
                                      v-model="filters['global'].value"
                            >
                                <template #prepend>
                                    <va-icon name="search"/>
                                </template
                                >
                            </va-input>
                            <Button label="Search" class="ml-1"/>
                        </div>
                        <div class="flex flex-row justify-content-end mt-2">
                            <Button label="Add" class="add-btn" @click="handleAdd"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No landmarks found.
                </template>
                <ColumnGroup type="header">
                    <Row>
                        <Column headerStyle="width: 3em"></Column>
                        <Column header="Name" :colspan="2"></Column>
                        <Column header="Coordinates"></Column>
                        <Column header="Address"></Column>
                        <Column header="Description"></Column>
                        <Column header="Action">
                        </Column>
                    </Row>
                </ColumnGroup>
                <Column selectionMode="multiple" headerStyle="width: 3em"></Column>

                <Column field="images" header="">
                    <template #body="slotProps">
                        <img class="w-10rem" :src="showLandmarkImage(slotProps)"/>
                    </template>
                </Column>
                <Column field="name" header="Name"></Column>
                <Column field="coordinatesDms" header="Coordinates">

                </Column>
                <Column field="address" header="Address"></Column>
                <Column field="description" header="Description">
                    <template #body="slotProps">
                        <!--                        showLog(slotProps.data.description)-->
                        <p>{{ showDescription(slotProps.data.description) }}</p>
                    </template>
                </Column>
                <Column field="action" header="Action">
                    <template #body="slotProps">
                        <Button
                                icon="pi pi-eye"
                                class="p-button-rounded p-button-text p-button-plain"
                                style="color: #000000B2"
                                @click="handleView(slotProps.data)"
                        />
                        <Button
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-text p-button-plain"
                                style="color: #000080CC"
                                @click="handleUpdate(slotProps.data)"
                        />
                        <Button
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-text p-button-plain"
                                style="color: red"
                                @click="handleDelete(slotProps.data)"
                        />
                    </template>
                </Column>
            </DataTable>
        </div>
        <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"
                       group="landmarkList"></ConfirmDialog>
        <LandmarksDialog :action="action" :header="header" :visible="showAdd" :landmark="landmarkToView"
                         @on-hide="handleHide"
                         @on-saved="handleSuccess"
                         @on-updated="handleUpdated"></LandmarksDialog>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Row from "primevue/row"
    import ColumnGroup from "primevue/columngroup"
    import apiService from "../../../../service/apiService";
    import {FilterMatchMode} from "primevue/api";
    import ConfirmDialog from "primevue/confirmdialog"

    import LandmarksDialog from "./LandmarksDialog";

    export default {
        components: {
            Button,
            DataTable,
            Column,
            Row,
            ColumnGroup,
            ConfirmDialog,
            LandmarksDialog
        },
        data() {
            return {
                landmarks: null,
                currentQuery: null,
                isLoading: false,
                selectedLandmarks: [],
                showAdd: false,
                filters: {
                    "global": {value: null, matchMode: FilterMatchMode.CONTAINS},
                },
                landmarkToAdd: null,
                landmarkToView: null,
                name: "",
                address: "",
                lat: "",
                lng: "",
                desc: "",
                dialogLoad: false,
                header: "Add Landmark",
                action: 0
            }
        },
        methods: {
            showToast(message, severity) {
                this.$toast.add({
                    severity: severity,
                    detail: message,
                    group: 'br',
                    life: 3000
                });
            },
            showDialog(message, accept, reject) {
                this.$confirm.require({
                    group: "landmarkList",
                    message: message,
                    header: "Confirmation",
                    accept: accept,
                    reject: reject
                })
            },
            async fetchAnother() {
                if (this.currentQuery !== null) {
                    const response = await apiService
                        .getLandmarkList(10, this.currentQuery)
                        .catch((error) => {
                            this.showToast(error.response.data.message);
                        });
                    this.currentQuery = response.data.data.query;
                    this.landmarks.push(...response.data.data.landmarks);
                }
            },
            handleAdd() {
                this.header = "Add Landmark"
                this.showAdd = true
                this.action = 0
            },
            handleUpdate(data) {
                this.header = "Update Landmark"
                this.showAdd = true
                this.action = 2
                this.landmarkToView = data
            },
            handleDelete(data) {
                if (this.selectedLandmarks.length <= 0) {
                    //Single delete
                    this.showDialog(`Are you sure you want to delete "${data.name}"?`, () => {
                        return this.deleteLandmark(data.id)
                    }, () => {

                    })
                } else {
                    const names = []
                    const ids = []
                    this.selectedLandmarks.map(landmark => {
                        names.push(landmark.name)
                        ids.push(landmark.id)
                    })
                    this.showDialog(`Are you sure you want to delete ${names.join(",")}?`, () => {
                        return this.deleteLandmark(ids)
                    }, () => {

                    })
                }
            },
            async deleteLandmark(id) {
                this.isLoading = true
                try {
                    const response = await apiService.deleteLandmark(id)
                    this.showToast(response.data.message, "success")
                    if (!Array.isArray(id)) {
                        this.landmarks = this.landmarks.filter(landmark => landmark.id !== id)
                    } else {
                        this.selectedLandmarks.map(landmark => {
                            this.landmarks = this.landmarks.filter(_landmark => _landmark !== landmark)
                        })
                    }
                    this.isLoading = false
                    this.selectedLandmarks = null
                } catch (e) {
                    this.showToast(e, "error")
                    this.isLoading = false
                }
            },
            handleView(data) {
                this.header = "Landmark Info"
                this.landmarkToView = data
                this.showAdd = true
                this.action = 1
            },
            handleHide() {
                this.showAdd = false
                this.landmarkToView = null
            },
            handleSuccess(landmark) {
                this.landmarks.push(landmark)
                this.showToast(`${landmark.name} added successfully!`, "success")
            },
            handleUpdated(_landmark) {
                //objIndex = myArray.findIndex((obj => obj.id == 1));
                const index = this.landmarks.findIndex((landmark => landmark.id === _landmark.id))
                this.landmarks[index] = _landmark
                this.showToast(`${_landmark.name} updated successfully!`, "success")
            },
            showLandmarkImage(data) {
                if (data.data.images) {
                    if (data.data.images.length > 0) {
                        return data.data.images[0]
                    } else {
                        return require("../../../../assets/placeholder.png")
                    }
                }
            },
            showLog(data) {
                console.log(data)
            },
            showDescription(data) {
                console.log(data)
                if (data && data.length > 0) {
                    return data
                } else {
                    return "No description"
                }
            }
        },
        async created() {
            this.isLoading = true;
            try {
                const response = await apiService.getLandmarkList(null, null);
                this.currentQuery = response.data.data.query;
                this.isLoading = false;
                this.landmarks = response.data.data.landmarks;
                await this.fetchAnother();
            } catch (error) {
                this.isLoading = false;
            }
        },
    };
</script>

<style scoped>
    .landmarks-list {
        margin-top: 23px;
    }

    /*.row {*/
    /*    display: flex;*/
    /*    justify-content: flex-end;*/
    /*    color: purple*/
    /*}*/

    .add-btn {
        width: 144px;
        height: 38px;
        color: #ffffff;
        background: #060645;
        border-radius: 10px;
        font-size: 20px;
        line-height: 27px;
    }

    ::v-deep(.p-datatable-header) {
        background-color: white;
    }

    .header-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
</style>
