<template>
    <ProgressBar mode="indeterminate" v-if="isLoading"/>
    <div class="grid">
        <div class="col-12">
            <UploadDisplayImage :_image="getProfilePicture" @on-picked="handleFilePicked"></UploadDisplayImage>
        </div>
        <div class="col-12 flex flex-row justify-content-center">
            <EditProfileForm @on-will-update="handleFormData"></EditProfileForm>
        </div>
    </div>
    <Toast position="bottom-right"/>

</template>

<script>
    import EditProfileForm from "./EditProfileForm";
    import UploadDisplayImage from "../settings/add_admin/UploadDisplayImage";
    import ProgressBar from "primevue/progressbar"

    import apiService from "../../../service/apiService";

    export default {
        name: "EditProfile",
        components: {
            EditProfileForm,
            UploadDisplayImage,
            ProgressBar,
        },
        data() {
            return {
                mFile: null,
                isLoading: false
            }
        },
        methods: {
            showToast(message, severity) {
                this.$toast.add({
                    severity: severity,
                    detail: message,
                    group: 'br',
                    life: 3000
                });
            },
            handleFilePicked(file) {
                this.mFile = file
            },
            async handleFormData(data) {
                this.isLoading = true
                data.profilePicture = localStorage.getItem('profilePicture')
                data.userId = localStorage.getItem("id")
                data.file = this.mFile
                try {
                    const updatedData = await apiService.updateAdmin(data)
                    this.isLoading = false
                    this.showToast("Data updated successfully!", "success")
                    localStorage.setItem("name", updatedData.data.data.name)
                    localStorage.setItem("email", updatedData.data.data.email)
                    localStorage.setItem("phone", updatedData.data.data.phone)
                    if (updatedData.data.data.profilePicture) {
                        localStorage.setItem("profilePicture", updatedData.data.data.profilePicture)
                    }
                    this.$router.back()
                } catch (e) {
                    this.isLoading = false
                    this.showToast(e, "error")
                }
            }
        },
        computed: {
            getProfilePicture() {
                return localStorage.getItem('profilePicture')
            }
        }
    }
</script>

<style scoped>
    .progress-spinner {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        /*overflow: show;*/
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
</style>