<template>
    <Dialog :header="header" @hide="handleHide" v-model:visible="isVisible" modal :style="{width: '90vw'}">
        <ProgressBar v-if="isLoading" mode="indeterminate"/>
        <Message v-if="!isLoading && isSearchFail" severity="error">{{this.searchErrorMessage}}</Message>
        <div class="p-d-flex dialog-body">
            <div class="p-fluid p-col col-2">
                <div class="p-field">
                    <div class="grid" v-if="action === 0">
                        <div class="col-12 lg:col-9">
                            <InputText id="addSearch" type="text" placeholder="Search" @keyup.enter="handleSearch"
                                       v-model="landmarkToAdd"/>
                        </div>
                        <div class="col-12 lg:col-3">
                            <Button class="btnSave" :disabled="!landmarkToAdd" label="Search" @click="handleSearch"/>
                        </div>
                    </div>
                </div>
                <div class="p-field mt-3">
                    <label for="addName">Landmark Name*</label>
                    <InputText id="addName" type="text" v-model="name" :disabled="action === 1"/>
                </div>
                <div class="p-field mt-3">
                    <label for="addLattitude">Lattitude*</label>
                    <InputText id="addLattitude" type="number" v-model="lat" :disabled="action === 1"/>
                </div>
                <div class="p-field mt-3">
                    <label for="addLongtitude">Longtitude*</label>
                    <InputText id="addLongtitude" type="number" v-model="lng" :disabled="action === 1"/>
                </div>
                <div class="p-field mt-3">
                    <label for="addAddress">Address*</label>
                    <InputText id="addAddress" type="text" v-model="address" :disabled="action === 1"/>
                </div>
                <div class="p-field mt-3">
                    <label for="addLandmarkName">Landmark Description</label>
                    <Textarea id="addLandmarkName" rows="5" cols="30" v-model="desc" :disabled="action === 1"/>
                </div>
            </div>
            <div class="p-col col-2">
                <div class="flex flex-column align-items-center">
                    <p class="text-base upload-text">Upload Image</p>
                    <FileUpload :imageUrl="getImage(0)" @on-picked="handleFilePicked"
                                @on-deleted="handleDelete" :action="mAction"></FileUpload>
                    <div class="flex flex-row w-full justify--space-around mt-3">
                        <FileUpload :image-url="getImage(1)" @on-picked="handleFilePicked" @on-deleted="handleDelete"
                                    :height="`h-10rem`" :action="mAction"></FileUpload>
                        <FileUpload :image-url="getImage(2)" @on-picked="handleFilePicked" @on-deleted="handleDelete"
                                    :height="`h-10rem`" :action="mAction"></FileUpload>
                        <FileUpload :image-url="getImage(3)" @on-picked="handleFilePicked" @on-deleted="handleDelete"
                                    :height="`h-10rem`" :action="mAction"></FileUpload>
                    </div>
                    <div v-if="action === 0 || action === 2" class="grid col-12 mt-3 lg:mt-6">
                        <div class="col-12 lg:col-6">
                            <Button id="btnCancel" class="w-full h-3rem" label="Cancel" @click="isVisible = false"/>
                        </div>
                        <div class="col-12 lg:col-6">
                            <Button id="btnSave" class="w-full h-3rem" label="Save" @click="handleSave"/>
                        </div>
                    </div>
                </div>
            </div>
            <Toast position="bottom-right"/>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import InputText from "primevue/inputtext";
    import Textarea from "primevue/textarea";
    import ProgressBar from "primevue/progressbar";
    import FileUpload from "../../../ui/fileUpload/FileUpload";
    import Toast from "primevue/toast";
    import apiService from "../../../../service/apiService";
    import Button from "primevue/button";
    import Message from "primevue/message";

    export default {
        name: "LandmarksDialog",
        data() {
            return {
                isLoading: false,
                isSearchFail: false,
                isVisible: false,
                address: "",
                name: "",
                lat: "",
                lng: "",
                desc: "",
                files: null,
                images: null,
                searchErrorMessage: "Error searching for landmark",
                mAction: null,
                landmarkToAdd: null
            }
        },
        props: {
            visible: {type: Boolean, required: true, default: false},
            landmark: {type: Object, required: false},
            header: {type: String, required: false, default: "Add Landmark"},
            action: {type: Number, required: false, default: 0}
        },
        components: {
            Dialog,
            InputText,
            Textarea,
            ProgressBar,
            FileUpload,
            Toast,
            Button,
            Message
        },
        methods: {
            async handleSearch() {
                try {
                    this.isSearchFail = false
                    this.isLoading = true
                    const response = await apiService.searchLandmark(this.landmarkToAdd)
                    this.isLoading = false
                    const {address, name, lat, lng, desc, images} = response.data.data
                    this.address = address
                    this.name = name
                    this.lat = lat
                    this.lng = lng
                    this.desc = desc
                    this.images = images
                } catch (e) {
                    this.dialogLoad = false
                    this.isLoading = false
                    this.isSearchFail = true
                    this.searchErrorMessage = "Error searching for landmark named \"" + this.landmarkToAdd + "\""
                }
            },
            async handleSave() {
                try {
                    this.isLoading = true
                    let response = null
                    if (this.action === 0) {
                        response = await
                            apiService.addLandmark({
                                name: this.name,
                                lat: this.lat,
                                lng: this.lng,
                                desc: this.desc,
                                address: this.address,
                                files: this.files,
                                images: this.images
                            })
                        this.$emit("on-saved", response?.data.data.landmark)
                    } else if (this.action === 2) {
                        response = await
                            apiService.updateLandmark({
                                name: this.name,
                                lat: this.lat,
                                lng: this.lng,
                                description: this.desc,
                                address: this.address,
                                files: this.files,
                                images: this.images,
                                geoPoint: this.landmark.geoPoint,
                                addressLower: this.landmark.addressLower,
                                coordinatesDms: this.landmark.coordinatesDms,
                                keywords: this.landmark.keywords,
                                nameLower: this.landmark.nameLower,
                                id: this.landmark.id
                            })
                        this.$emit("on-updated", response?.data.data)
                    }
                    this.isLoading = false
                    this.formDefaults()
                    this.$emit("on-hide")
                } catch (e) {
                    this.isLoading = false
                    this.isSearchFail = true
                    this.searchErrorMessage = e.response.data.message.replace(",", ", ")
                }
            },
            formDefaults() {
                this.name = ""
                this.address = ""
                this.lat = ""
                this.lng = ""
                this.desc = ""
                this.images = null
                this.files = null
            },
            handleHide() {
                this.formDefaults()
                this.$emit("on-hide")
            },
            getImage(index) {
                let imageUrl = "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                if (this.images !== null) {
                    if (index < this.images.length) {
                        imageUrl = this.images[index]
                    }
                }
                return imageUrl
            },
            handleFilePicked(file) {
                console.log(`handleFilePicked: ${file}`)
                if (this.files !== null) {
                    this.files.push(file)
                    console.log(this.files)
                } else {
                    const imgs = []
                    imgs.push(file)
                    this.files = imgs
                    console.log(this.files)
                }
            },
            handleDelete(url, _file) {
                console.log(`URL ${url}`)
                console.log(`File ${_file}`)
                if (_file) {
                    this.files = this.files.filter((file) => {
                        return file !== _file
                    })
                    console.log(this.files)
                } else {
                    this.images = this.images.filter((img) => {
                        return img !== url
                    })
                    console.log(this.images)
                }
            }
        },
        watch: {
            landmark() {
                if (this.$props.landmark !== null) {
                    const {address, name, lat, lng, description, images} = this.$props.landmark
                    this.address = address
                    this.name = name
                    this.lat = lat
                    this.lng = lng
                    this.desc = description
                    this.images = images
                }
            },
            visible() {
                this.isVisible = this.$props.visible
            },
            action() {
                if (this.$props.action) {
                    this.mAction = this.$props.action
                }
            },
        },
        emits: ["on-hide", "on-saved", "on-updated"]
    }
</script>

<style scoped>
    .dialog-body {
        display: flex;
        width: 100%;
    }

    .purple {
        background-color: purple;
    }

    .col-2 {
        width: 50%;
    }

    .upload-text {
        color: #060645;
        font-family: 'Nunito', sans-serif;
    }

    #btnCancel {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 10px;
        color: #000000;
    }

    #btnCancel:hover, #btnSave:hover {
        opacity: 0.5
    }

    #btnSave {
        background: #071D36;
        border-radius: 10px;
        border-color: #071D36;
    }
</style>