<template>
<div class="flex flex-column md:pl-8 md:pr-8">
<img class="center mt-7" src="../../assets/orbeas_rogue_logo.svg"/>
<label class="mt-5  md:mt-6 lg:mt-8" for="username">Username/Email</label>
<text-field
id="username"
type="username"
maxLength="50"
v-model="username"
@keyup.enter="handleClick"
/>
<label class="mt-3" for="password">Password</label>
<text-field
id="password"
type="password"
maxLength="50"
v-model="password"
@keyup.enter="handleClick"
/>
<m-button
class="mt-3 custom-btn"
label="Login"
@click="handleClick"
></m-button>
<div class="flex flex-row justify-content-center mt-2 mb-2">
<router-link to="forgot" class="forgot">Forgot Password</router-link>
</div>
</div>
</template>

<script>
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";

    import loginService from "../../service/apiService";

    import localStorageUtil from "../../utils/localStorageUtil";

    export default {
        data() {
            return {
                username: "",
                password: "",
                show: false,
            };
        },
        components: {
            "text-field": InputText,
            "m-button": Button,
        },
        methods: {
            async handleClick() {
                this.emitLoad(true);
                await this.handleResponse();
            },
            emitLoad(isLoading) {
                this.$emit("is-loading", isLoading);
            },
            goToDashboard() {
                this.$emit("on-success")
                this.$router.replace({name: "accounts"});
            },
            async handleResponse() {
                try {
                    await loginService
                        .signIn(this.username, this.password)
                    this.emitLoad(false)
                    localStorageUtil.setLoggedIn(true);
                    const adminDetails = await loginService.getAdminDetails(this.username)
                    if (adminDetails.data.profilePicture) {
                        localStorage.setItem("profilePicture", adminDetails.data.profilePicture)
                    }
                    localStorage.setItem("name", adminDetails.data.name)
                    localStorage.setItem("id", adminDetails.data.id)
                    localStorage.setItem("email", adminDetails.data.email)
                    localStorage.setItem("phone", adminDetails.data.phone)
                    this.goToDashboard();
                } catch (error) {
                    console.log(error)
                    let errorMessage = error.message;
                    if (error.response.data) {
                        errorMessage = error.response.data.message
                    }
                    this.$emit("on-error", errorMessage)
                    this.emitLoad(false)
                }
            },
        },
        emits: ["is-loading", "on-error", "on-success"],
    };
</script>

<style scoped>
label {
color: #000000B2;
}

.forgot {
color: #000000B2;
text-decoration: none
}

.forgot:hover {
cursor: pointer;
}

text-field {
border: 1px solid rgba(0, 0, 0, 0.5);
box-sizing: border-box;
border-radius: 10px;
}

text-field:hover {
border-color: #090c4b;
}

.custom-btn {
background: #071E38;
border-color: #090c4b;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 33px;
}

.custom-btn:hover {
opacity: 0.5;
background-color: #090c4b;
}

.responsive {
width: 100%;
max-width: 600px;
height: auto;
}

.center {
/* display: block; */
margin-left: auto;
margin-right: auto;
}


</style>
