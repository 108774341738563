<template>
    <div class="flex flex-column w-full">
        <div class="border w-full" :class="[height]">
            <img v-if="imageShown" class="w-full"
                 :src="image" :class="[height]"/>
            <div class="w-full h-full flex flex-row justify-content-center align-items-center">
                <input ref="file" type="file" style="display: none; object-fit: cover;" accept="image/*"
                       @input="handleFile"/>
                <Button v-if="showButton" icon="pi pi-plus" class="p-button-rounded p-button-secondary"
                        style="z-index: 1"
                        @click="showFilePicker"/>
            </div>
        </div>
        <div>
            <ImageAction v-if="imageShown && action !== 1" @on-edit="handleEdit" @on-delete="handleDelete"
                         class="mt-2"/>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button"
    import ImageAction from "./ImageAction";

    export default {
        components: {
            Button,
            ImageAction
        },
        props: {
            height: {
                type: String,
                default: "h-15rem"
            },
            imageUrl: {
                type: String,
                default: null
            },
            action: {
                type: Number,
                default: 1
            }
        },
        name: "FileUpload",
        emits: ["on-picked", "on-deleted"],
        data() {
            return {
                image: null,
                imageShown: false,
                showButton: true,
                files: [],
                file: null,
                mAction: null
            }
        },
        methods: {
            handleFile(event) {
                console.log("handleFile")
                console.log(event)
                this.displayThumbnail(event)
            },
            showFilePicker() {
                this.$refs.file.value = null
                this.$refs.file.click()
            },
            displayThumbnail(event) {
                const f = event.target.files[0]
                this.$emit("on-picked", f)
                this.files.push(f)
                this.file = f
                const reader = new FileReader()
                reader.readAsDataURL(f)
                reader.onload = () => {
                    this.image = reader.result
                    this.imageShown = true
                    this.showButton = false
                }
            },
            handleEdit() {
                this.showFilePicker()
            },
            handleDelete() {
                this.$emit("on-deleted", this.image, this.file)
                this.image = null
                this.file = null
                this.imageShown = false
                this.showButton = true
            }
        },
        created() {
            console.log("created")
            if (this.$props.imageUrl.trim() === "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=") {
                this.imageShown = false
                this.showButton = true
            } else {
                this.image = this.$props.imageUrl
                if (this.$props.imageUrl) {
                    if (this.$props.imageUrl.trim() === "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=") {
                        this.imageShown = false
                        this.showButton = true
                    } else {
                        this.imageShown = true
                        this.showButton = false
                    }
                } else {
                    this.imageShown = false
                    this.showButton = true
                }
            }
        },
        watch: {
            imageUrl() {
                this.image = this.$props.imageUrl
                if (this.$props.imageUrl) {
                    if (this.$props.imageUrl.trim() === "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=") {
                        this.imageShown = false
                        this.showButton = true
                    } else {
                        this.imageShown = true
                        this.showButton = false
                    }
                } else {
                    this.imageShown = false
                    this.showButton = true
                }
            },
            action() {
                console.log("watch: action")
                if (this.$props.action) {
                    console.log("watch: action")
                    this.mAction = this.$props.action
                }
            }
        }
    }
</script>

<style scoped>
    .border {
        border: 1px dashed rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        border-radius: 10px;
    }

    ::v-deep(.p-button) {
        background-color: #808080
    }

    ::v-deep(.p-button:hover) {
        opacity: 0.5
    }

    img {
        object-fit: cover;
    }
</style>