<template>
    <div class="wrapper h-screen py-4 px-3">
        <ProgressBar v-if="isLoading" mode="indeterminate"/>
        <Card>
            <template #content>
                <div class="grid">
                    <div class="col-12 lg:col-2">
                        <div class="flex flex-row justify-content-center lg:pt-6">
                            <i class="pi pi-user pt-3 "></i>
                            <p class="ml-2 lg:text-base"><b>Users</b></p>
                        </div>
                    </div>
                    <div class="col-12 lg:col-10">
                        <router-view v-slot="{ Component }">
                            <component @on-loading="handleLoad" @add-success="handleAddSuccess"
                                       @on-error="handleError" :is="Component"/>
                        </router-view>
                    </div>
                </div>
            </template>
        </Card>
    </div>
    <Toast position="bottom-right"/>
</template>

<script>
    import Card from 'primevue/card';
    import ProgressBar from "primevue/progressbar"

    export default {
        components: {
            Card,
            ProgressBar
        },
        data() {
            return {
                isLoading: false
            }
        },
        created() {
            this.$router.push({name: "adminList"})
        },
        methods: {
            handleLoad(bool) {
                this.isLoading = bool
            },
            showToast(message, severity) {
                this.$toast.add({
                    severity: severity,
                    detail: message,
                    group: 'br',
                    life: 3000
                });
            },
            handleAddSuccess() {
                this.showToast("Admin added successfully!", "success")
                this.$router.replace({name: "adminList"})
            },
            handleError(error) {
                this.showToast(error, "error")
            }
        }
    }
</script>

<style scoped>
    .wrapper {
        background-color: #E5E5E5;
    }
</style>