<template>
    <div class="flex flex-column align-items-center justify-content-center h-full">
        <p class="lg:text-base"><b>Upload Image</b></p>
        <div class="container w-12rem h-12rem">
            <div class="outer h-12rem w-12rem">
                <img class="h-12rem w-12rem"
                     :src="image"/>
                <div class="inner">
                    <input class="inputfile" type="file" name="pic" accept="image/*" @change="handleFile">
                    <label>
                        <i class="pi pi-camera" style="fontSize: 1rem"></i>
                    </label>
                </div>
            </div>
        </div>
        <p>At least 256px by 256px PNG or JPG file.
            Maximum size 4mb</p>
    </div>
</template>

<script>
    export default {
        name: "UploadDisplayImage",
        props: {
            _image: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                image: require('../../../../assets/ic_placeholder.svg'),
            }
        },
        methods: {
            handleFile(event) {
                this.displayThumbnail(event)
            },
            displayThumbnail(event) {
                const f = event.target.files[0]
                const reader = new FileReader()
                reader.readAsDataURL(f)
                reader.onload = () => {
                    this.image = reader.result
                }
                this.$emit("on-picked", f)
            }
        },
        created() {
            if (this._image) {
                this.image = this._image
            }
        },
        emits: ["on-picked"]
    }
</script>

<style scoped>
    img {
        border-radius: 50%;
    }

    .container {
        display: block;
        margin: 0 auto;
    }

    .outer {
        margin: auto;
        background-color: #C4C4C4;
        border-radius: 100%;
        position: relative;
    }

    .inner {
        background-color: #C4C4C4;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .inputfile {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        width: 50px;
        height: 50px;
    }

    .inputfile + label {
        font-size: 1.25rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        width: 50px;
        height: 50px;
        pointer-events: none;
        cursor: pointer;
        line-height: 50px;
        text-align: center;
    }

    .inputfile + label svg {
        fill: #fff;
    }
</style>