<template>
    <div class="grid">
        <div class="grid col-12" style="padding: 0; margin: 0">
            <div class="col-6 flex flex-row align-items-center">
                <p class="lg:text-base"><b>Admins</b></p>
            </div>
            <div class="col-6 flex flex-row justify-content-end align-items-center">
                <Button @click="goToAddAdminForm" label="Add" class="w-3 w-5 lg:h-2rem"/>
            </div>
        </div>
        <div class="col-12">
            <DataTable
                    style="padding: 0; margin: 0"
                    :loading="isLoading"
                    :paginator="true"
                    :rows="10"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :value="admins"
                    :dataKey="id"
                    responsiveLayout="scroll"
                    v-model:selection="selectedAdmins"
                    :row-hover="true"
            >
                <template #empty>
                    No users found.
                </template>
                <Column field="images">
                    <template #body="slotProps">
                        <Avatar :image="getImage(slotProps)" size="xlarge" shape="circle"/>
                    </template>
                </Column>
                <Column field="name" header="Name"></Column>
                <Column field="email" header="Email"></Column>
                <Column field="action" header="Action">
                    <template #body="slotProps">
<!--                        <Button-->
                        <!--                                icon="pi pi-pencil"-->
                        <!--                                class="p-button-rounded p-button-text p-button-plain"-->
                        <!--                                style="color: #071D36;"-->
                        <!--                        />-->
                        <Button
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-text p-button-plain"
                                style="color: red"
                                @click="handleDeletion(slotProps.data)"
                        />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"
                   group="adminlist"></ConfirmDialog>
    <Toast position="bottom-right"/>
</template>

<script>
    import DataTable from "primevue/datatable"
    import Column from "primevue/column";
    import Button from "primevue/button"
    import ConfirmDialog from "primevue/confirmdialog"
    import Avatar from "primevue/avatar"

    import apiService from "../../../../service/apiService";

    export default {
        data() {
            return {
                admins: null,
                isLoading: false,
                selectedAdmins: []
            }
        },
        methods: {
            showToast(message, severity) {
                this.$toast.add({
                    severity: severity,
                    detail: message,
                    group: 'br',
                    life: 3000
                });
            },
            showDialog(message, accept, reject) {
                this.$confirm.require({
                    group: "adminlist",
                    message: message,
                    header: "Confirmation",
                    accept: accept,
                    reject: reject
                })
            },
            goToAddAdminForm() {
                this.$router.push({name: "addAdmin"})
            },
            handleDeletion(data) {
                let message = ""
                if (Array.isArray(this.selectedAdmins) && this.selectedAdmins.length) {
                    const ids = []
                    this.selectedAdmins.map(user => {
                        ids.push(user.id)
                    })
                    message = `Are you sure you want to remove ${ids.join()}?`
                    this.showDialog(message, () => {
                        return this.deleteAdmin(ids)
                    }, () => {

                    })
                } else {
                    message = `Are you sure you want to remove ${data.email}?`
                    this.showDialog(message, () => {
                        return this.deleteAdmin(data.id)
                    }, () => {

                    })
                }
            },
            async deleteAdmin(data) {
                this.isLoading = true
                try {
                    await apiService.deleteAdmin(data)
                    this.isLoading = false
                    this.showToast("Admin deleted successfully!", "success")
                    if (Array.isArray(data)) {
                        this.selectedAdmins.map((_admin) => {
                            this.admins = this.admins.filter(admin => _admin !== admin)
                        })
                    } else {
                        this.admins = this.admins.filter(user => user !== data)
                    }
                    this.selectedAdmins = []
                } catch (exception) {
                    this.showToast(exception, "error")
                    this.isLoading = false
                }
            },
            getImage: function (data) {
                console.log(data.data)
                if (data.data.profilePicture) {
                    return data.data.profilePicture
                } else {
                    return "https://www.seekpng.com/png/detail/966-9665317_placeholder-image-person-jpg.png"
                }
            }
        },
        name: "AdminList",
        components: {
            DataTable,
            Button,
            Column,
            ConfirmDialog,
            Avatar
        },
        computed: {},
        async created() {
            this.isLoading = true
            try {
                const response = await apiService.getAdmins()
                this.admins = response.data.data.admins
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
                this.show(e, "error")
            }
        }
    }
</script>

<style scoped>
    Button {
        background: #071D36;
        border-radius: 10px;
        border-color: #071D36;
        padding: 0;
        margin: 0
    }
</style>