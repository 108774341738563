<template>
    <div class="flex flex-row justify-content-center">
<!--        <Button @click="handleEdit" label="Edit" id="edit"/>-->
        <Button @click="handleDelete" label="Delete" id="delete"/>
    </div>
</template>

<script>
    import Button from "primevue/button"

    export default {
        name: "imageAction",
        components: {
            Button
        },
        methods: {
            handleEdit() {
                this.$emit("on-edit")
            },
            handleDelete() {
                this.$emit("on-delete")
            }
        },
        emits: ["on-edit", "on-delete"]
    }
</script>

<style scoped>
    #edit {
        background-color: #071D36;
        color: white;
        border-color: #071D36;
    }

    #delete {
        background-color: red;
        color: white;
        border-color: red;
    }
</style>