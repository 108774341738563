<template>
    <div class="grid mt-3 admin-panel">
        <div class="col-1"></div>
        <div class="col-10">
            <admin-panel-nav></admin-panel-nav>
<!--            <teleport to="#modal ">-->
<!--                <ProgressBar v-if="isLoading" mode="indeterminate"/>-->
<!--            </teleport>-->
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component @loading="handleLoad" :is="Component" :key="$route.fullPath"/>
                </keep-alive>
            </router-view>
        </div>
        <div class="col-1"></div>
    </div>
</template>

<script>
    import AdminPanelNav from "./AdminPanelNav.vue";
    import SearchBar from "./SearchBar.vue";
    import ProgressBar from "primevue/progressbar"

    export default {
        components: {
            AdminPanelNav,
            SearchBar,
            ProgressBar
        },
        data() {
            return {
                isLoading: true
            }
        },
        methods: {
            handleLoad(bool) {
                console.log("handleLoad")
                this.isLoading = bool
            }
        }
    };
</script>

<style scoped>
    .search-btn button {
        background-color: #338AA1;
        height: 54px;
        width: 173px;
        border-radius: 10px;
    }
</style>
