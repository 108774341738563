<template>
    <div class="flex flex-column">
        <p class="lg:text-lg"><b>Account Information</b></p>
        <form autocomplete="off">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6">
                    <label for="firstname">First Name</label>
                    <InputText id="firstname" type="text" class="w-full" autocomplete="off" maxlength="50"
                               v-model="firstname"/>
                </div>
                <div class="field col-12 md:col-6">
                    <label for="lastname">Last Name</label>
                    <InputText id="lastname" type="text" class="w-full" autocomplete="off" maxlength="50"
                               v-model="lastname"/>
                </div>
                <div class="field col-12">
                    <label for="email">Email Address</label>
                    <InputText id="email" type="email" class="w-full" autocomplete="off" maxlength="50"
                               v-model="email"/>
                </div>
                <div class="field col-12">
                    <label for="phone">Phone Number</label>
                    <InputText id="phone" type="number" class="w-full" autocomplete="off" maxlength="15"
                               v-model="phone"/>
                </div>
            </div>
            <p class="lg:text-lg"><b>Passwords</b></p>
            <div class="grid formgrid">
                <div class="field col-12">
                    <label for="pass1">Password</label>
                    <InputText id="pass1" type="password" class="w-full" autocomplete="off" v-model="password"/>
                </div>
                <div class="field col-12">
                    <label for="pass2">Re-type Password</label>
                    <InputText id="pass2" type="password" class="w-full" autocomplete="off" v-model="password2"/>
                </div>
            </div>
            <div class="grid col-12">
                <div class="col-12 sm:col-6">
                    <Button @click="handleOnCancel" id="btnCancel" class="w-full" label="Cancel"/>
                </div>
                <div class="col-12 sm:col-6">
                    <Button id="btnSave" class="w-full" label="Save" @click="saveAdmin"/>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';


    export default {
        name: "AccountForm",
        components: {
            InputText,
            Button
        },
        props: {
            imageFile: {
                type: File,
                default: null
            },
            default: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                email: "",
                password: "",
                password2: "",
                firstname: "",
                lastname: "",
                phone: "",
                mImageFile: null
            }
        },
        methods: {
            handleOnCancel() {
                this.$emit("on-cancel")
            },
            async saveAdmin() {
                const admin = {
                    email: this.email,
                    password: this.password,
                    password2: this.password2,
                    firstName: this.firstname,
                    lastName: this.lastname,
                    phone: this.phone,
                }
                this.$emit("on-will-add", admin)
            },
            defaults() {
                this.email = ""
                this.password = ""
                this.password2 = ""
                this.firstname = ""
                this.lastname = ""
                this.phone = ""
                this.mImageFile = null
            }
        },
        created() {
            console.log(this.$props.imageFile)
        },
        watch: {
            imageFile() {
                console.log("watch")
                this.mImageFile = this.$props.imageFile
            },
            default() {
                if (this.default && this.default === true) {
                    this.defaults()
                }
            }
        },
        emits: ["on-cancel", "on-loading", "add-success", "on-will-add"]
    }
</script>

<style scoped>
    #btnCancel {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 10px;
        color: #000000;
    }

    #btnSave {
        background: #071D36;
        border-radius: 10px;
    }
</style>