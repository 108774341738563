<template>
    <div class="container class admin-panel-nav">
        <router-link class="px-5 admin-nav-btn" to="accounts"
        ><p class="text-base">Accounts</p></router-link
        >
        <router-link class="px-5 p ml-3 admin-nav-btn" to="landmarks"
        ><p class="text-base">Landmarks</p></router-link
        >
    </div>
</template>

<script>
    export default {};
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: row;
        justify-content: left;
    }

    a {
        text-decoration: none;
    }

    a:link {
        color: #000000;
        background: rgba(196, 196, 196, 0.5);
    }

    a:visited {
        color: black;
    }

    a:hover,
    a.active,
    a.router-link-active {
        color: white;
        background-color: #0F2B4C;
    }

    .admin-nav-btn {
        height: 53px;
        width: 271px;
        border-radius: 10px;
        padding: 0;

    }

    .admin-nav-btn p {
        margin: auto;
        padding: 11px;
        font-weight: 400;
        font-size: 23px;
        line-height: 31.37px;
        text-align: center;

    }
</style>
