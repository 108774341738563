<template>
    <div class="grid">
        <div class="lg:col-8">
            <ProgressBar v-if="isLoading" mode="indeterminate"/>
            <AccountForm :imageFile="mFile"
                         @on-loading="handleLoad"
                         @on-cancel="goToAdminList"
                         @on-will-add="handleAdminToAdd"
                         :default="defaults"
            ></AccountForm>
        </div>
        <div class="lg:col-4">
            <UploadDisplayImage @on-picked="handleFilePicked"></UploadDisplayImage>
        </div>
    </div>
</template>

<script>
    import AccountForm from "./AccountForm";
    import UploadDisplayImage from "./UploadDisplayImage";
    import ProgressBar from "primevue/progressbar"

    import apiService from "../../../../service/apiService";

    export default {
        name: "SettingsAddAdmin",
        components: {
            AccountForm,
            UploadDisplayImage,
            ProgressBar
        },
        data() {
            return {
                isLoading: false,
                mFile: null,
                admin: null,
                defaults: false
            }
        },
        methods: {
            goToAdminList() {
                this.$router.push({name: "adminList"})
            },
            handleLoad(bool) {
                this.isLoading = bool
            },
            handleSuccess() {
                this.$emit("add-success")
            },
            handleError(error) {
                this.$emit("on-error", error)
            },
            handleFilePicked(file) {
                console.log("handleFilePicked")
                this.mFile = file
            },
            async handleAdminToAdd(admin) {
                this.admin = admin
                await this.addAdmin()
            },
            async addAdmin() {
                this.isLoading = true
                try {
                    const images = []
                    if (this.mFile) {
                        images.push(this.mFile)
                        this.admin.files = images
                    }
                    this.isLoading = false
                    await apiService.addAdmin(this.admin)
                    this.isLoading = false
                    this.handleSuccess()
                    this.default = true
                } catch (e) {
                    this.$emit("on-error", e)
                    this.isLoading = false
                }
            }
        },
        emits: ["on-loading", "add-success", "on-error", "on-picked"]
    }
</script>

<style scoped>

</style>