const setLoggedIn = (isLogged) => {
    localStorage.setItem("isLoggedIn", isLogged);
};

const isLoggedIn = () => {
    return localStorage.getItem("isLoggedIn");
};

const setProfilePicture = (profilePicture) => {
    localStorage.setItem("profilePicture", profilePicture)
}

const getProfilePicture = () => {
    return localStorage.getItem("profilePicture")
}

const remove = (key) => {
    return localStorage.removeItem(key)
}

export default {
    setLoggedIn,
    isLoggedIn,
    remove,
    setProfilePicture,
    getProfilePicture
};
