<template>
    <div class="flex flex-column">
        <p class="lg:text-lg"><b>Account Information</b></p>
        <form autocomplete="off">
            <div class="formgrid grid">
                <div class="field col-12">
                    <label for="name">Name</label>
                    <InputText id="name" type="text" class="w-full" autocomplete="off" maxlength="50"
                               v-model="name"/>
                </div>
                <div class="field col-12">
                    <label for="email">Email Address</label>
                    <InputText id="email" type="email" class="w-full" autocomplete="off" maxlength="50"
                               v-model="email" disabled/>
                </div>
                <div class="field col-12">
                    <label for="phone">Phone Number</label>
                    <InputText id="phone" type="number" class="w-full" autocomplete="off" maxlength="15"
                               v-model="phone"/>
                </div>
            </div>
            <div class="grid col-12">
                <div class="col-12 sm:col-6">
                    <Button @click="back" id="btnCancel" class="w-full" label="Cancel"/>
                </div>
                <div class="col-12 sm:col-6">
                    <Button id="btnSave" class="w-full" label="Save" @click="handleUpdate"/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';

    export default {
        name: "EditProfileForm",
        components: {
            InputText,
            Button
        },
        data() {
            return {
                name: null,
                email: null,
                phone: null
            }
        },
        emits: ["on-will-update"],
        methods: {
            back() {
                this.$router.back()
            },
            handleUpdate() {
                this.$emit("on-will-update", {
                    name: this.name,
                    email: this.email,
                    phone: this.phone
                })
            }
        },
        created() {
            this.name = localStorage.getItem("name")
            this.email = localStorage.getItem("email")
            this.phone = localStorage.getItem("phone")
        }
    }
</script>

<style scoped>
    #btnCancel {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 10px;
        color: #000000;
    }

    #btnSave {
        background: #071D36;
        border-radius: 10px;
    }
</style>